<template>
  <div class="order_page">
     <div class="wrapper">
      <div class="image-holder">
        <img src="../assets/images/hungryy_logo_round.png" alt="Hungryy" />
      </div>
      <div id="wizard" v-if="this.placedOrder == false && this.isOrderDetails == false && this.orderListing == false">
          <div class="searchorder d-flex mb-5">
            <div class="row">
              <div class="form-holder d-flex">
                    <input
                      class="form-control mb-0 h50 ml-md-1"
                      type="text"
                      id="searchOrder"
                      name="searchOrder"
                      v-model="searchOrder"
                      placeholder="Track Your Delivery"
                      aria-label="Track Your Delivery"
                     
                    />
                     
                      <button @click="viewOrderDetails"  class="form-control ml-md-5 w-75" id="trackOrder">Track Order</button>
                     
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <form-wizard @on-complete="onComplete"
                      @on-loading="setLoading"
                      @on-validate="handleValidation"
                      shape="circle"
                      color="#e74c3c">
            <tab-content title="Collect From"
                        :before-change="validateAsync"
                        icon="ti-user">
                  <div class="form-row">
                      <div class="form-holder">
                        <label for=""> Sender Full Name * </label>
                        <input
                          class="form-control mb-0 h50"
                          type="text"
                          id="company_name"
                          name="company_name"
                          v-model="$v.step1.company_name.$model"
                          placeholder="Enter Sender Full Name"
                          aria-label="Sender Full Name"
                          v-on:keypress="isCharacter($event)"  
                          :class="{ 'is-invalid': $v.step1.company_name.$error }"
                        />

                        <div v-if="$v.step1.company_name.$error" class="invalid-feedback">
                          <span v-if="$v.step1.company_name.$error">Please enter Sender Full Name</span>
                        </div>
                        <!-- <input type="text" class="form-control" /> -->
                      </div>
                  </div>
                  <div class="form-row">
                      <div class="form-holder">
                        <label for=""> Sender Email * </label>
                        <input
                          class="form-control mb-0 h50"
                          type="text"
                          id="company_email"
                          name="company_email"
                          v-model="$v.step1.company_email.$model"
                          placeholder="Enter Sender Email Address"
                          aria-label="Enter Sender Email Address"
                          :class="{ 'is-invalid': $v.step1.company_email.$error }"
                        />

                        <div v-if="$v.step1.company_email.$error" class="invalid-feedback">
                          <span v-if="$v.step1.company_email.$error">Please enter Sender Email</span>
                        </div>
                        <!-- <input type="text" class="form-control" /> -->
                      </div>
                  </div>
                    <div class="form-row">
                      <div class="form-holder">
                        <label for=""> Sender Hp Number*</label>
                        <!-- <input type="text" class="form-control" /> -->
                        <input
                          class="form-control mb-0 h50"
                          type="text"
                          maxlength="8"
                          id="company_contact_number"
                          name="company_contact_number"
                          v-model="$v.step1.company_contact_number.$model"
                          placeholder="Enter Sender HP For OTP Verification"
                          aria-label="Enter Sender HP For OTP Verification"
                          @keyup.enter="checkNumber"
                          v-on:keypress="isNumber($event)"  
                          v-on:blur="checkNumber"
                          :class="{ 'is-invalid': $v.step1.company_contact_number.$error }"
                        />
                         <div v-if="$v.step1.company_contact_number.$error" class="invalid-feedback">
                          <span v-if="!$v.step1.company_contact_number.required">Please enter Sender HP Number</span>
                          <span v-if="!$v.step1.company_contact_number.numeric">Please enter numeric value</span>
                          <!-- <span v-if="$v.step2.contact_number.minlength">Please enter minimum 8 Characters</span> -->
                        </div>
                      </div>
                        <div class="loader" v-if="loadingOtp"></div>
                        <div id="recaptcha-container"></div>
                    </div>
                      <div class="otp-boxs mt-4 position-relative" v-if="showOtp == true" >
                        <div class="form-row">
                         <div class="form-holder">
                        <label for=""> OTP* </label>
                        <form method="get" class="digit-group" data-group-name="digits"  data-autosubmit="false" autocomplete="off">
                          <input type="text" id="digit-1" name="digit-1" data-next="digit-2"   v-on:keypress="isNumber($event)"   />
                          <input type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1"   v-on:keypress="isNumber($event)"   />
                          <input type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2"   v-on:keypress="isNumber($event)"  /><span class="splitter">&ndash;</span>
                          <input type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3"   v-on:keypress="isNumber($event)"   />
                          <input type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4"   v-on:keypress="isNumber($event)"   />
                          <input type="text" id="digit-6" name="digit-6" data-previous="digit-5"   v-on:keypress="isNumber($event)"   v-on:blur="verifyOTP"/>
                        </form>
                         </div>
                         </div>
                      </div>
                    
                    <div class="form-row">
                      <div class="form-holder">
                        <label for=""> Full Address</label>
                              <input
                               class="form-control mb-0 h50"
                            type="text"
                            id="company_address"
                            name="company_address"
                            v-model="$v.step1.company_address.$model"
                            placeholder="Full address inclusive of unit number eg. #02-05"
                            aria-label="Full address inclusive of unit number eg. #02-05"
                            :class="{'is-invalid':$v.step1.company_address.$error}"
                               
                              />
                                <div v-if="$v.step1.company_address.$error" class="invalid-feedback">
                                  <span v-if="$v.step1.company_address.$error">Please enter Sender Address</span>
                                </div>
                      </div>
                    </div>
                      <div class="form-row">
                      <div class="form-holder">
                        <label for=""> Address (For GPS)* </label>
                        <div class="search-box d-flex">
                           <div class="position-relative w-100">
                           <vue-google-autocomplete
                              type="text"
                               id="pickup_address"
                              name="pickup_address"
                              class="form-control"
                              placeholder="Search for an area or location name"
                              v-model="$v.step1.pickup_address.$model"
                              v-on:placechanged="getAddressData"
                              v-on:change="getChangAddressData"
                              :class="{'is-invalid':$v.step1.pickup_address.$error}"
                              
                              >
                            </vue-google-autocomplete>
                               <div v-if="$v.step1.pickup_address.$error" class="invalid-feedback">
                                  <span v-if="$v.step1.pickup_address.$error">Please enter Sender Address</span>
                                </div>
                           </div>
                        </div>
                        <!-- <input
                          class="form-control mb-0 h50"
                          type="text"
                          id="pickup_address"
                          name="pickup_address"
                          v-model="$v.step1.pickup_address.$model"
                          placeholder="Enter your pickup Address"
                          aria-label="Pickup Address"
                          :class="{ 'is-invalid': $v.step1.pickup_address.$error }"
                        /> -->

                      </div>
                    </div>
            </tab-content>
            <tab-content title="Send To"
                        :before-change="validateSecondAsync"
                        icon="ti-target">
                       <div class="form-row">
                          <div class="form-holder">
                            <label for=""> Receiver First Name * </label>
                            <input
                              class="form-control mb-0 h50"
                              type="text"
                              id="delivery_first_name"
                              name="delivery_first_name"
                              v-model="$v.step2.delivery_first_name.$model"
                              placeholder="Enter Receiver First Name"
                              aria-label="Receiver First Name"
                              v-on:keypress="isCharacter($event)"  
                              :class="{ 'is-invalid': $v.step2.delivery_first_name.$error }"
                            />
                            <div v-if="$v.step2.delivery_first_name.$error" class="invalid-feedback">
                              <span v-if="$v.step2.delivery_first_name.$error"
                                >Please enter Receiver First Name</span>
                            </div>
                          </div>
                       </div>
                       <div class="form-row">
                          <div class="form-holder">
                            <label for=""> Receiver Last Name * </label>
                            <input
                              class="form-control mb-0 h50"
                              type="text"
                              id="delivery_last_name"
                              name="delivery_last_name"
                              v-model="$v.step2.delivery_last_name.$model"
                              placeholder="Enter Receiver Last Name"
                              aria-label="Receiver Last Name"
                              v-on:keypress="isCharacter($event)"  
                              :class="{ 'is-invalid': $v.step2.delivery_last_name.$error }"
                            />
                            <div v-if="$v.step2.delivery_last_name.$error" class="invalid-feedback">
                              <span v-if="$v.step2.delivery_last_name.$error"
                                >Please enter Receiver Last Name</span>
                            </div>
                          </div>
                        </div>
                         <div class="form-row">
                     <div class="form-holder">
                        <label for=""> Receiver HP Number* </label>
                        <!-- <input type="text" class="form-control" /> -->
                        <input
                          class="form-control mb-0 h50"
                          type="text"
                          maxlength="8"
                          id="contact_number"
                          name="contact_number"
                          v-model="$v.step2.contact_number.$model"
                          placeholder="Enter Receiver Contact Number"
                          aria-label="Receiver Contact Number"
                          :class="{ 'is-invalid': $v.step2.contact_number.$error }"
                          v-on:keypress="isNumber($event)"  
                          
                        />
                        <div v-if="$v.step2.contact_number.$error" class="invalid-feedback">
                          <span v-if="!$v.step2.contact_number.required">Please enter Receiver HP Number</span>
                          <span v-if="!$v.step2.contact_number.numeric">Please enter numeric value</span>
                          <!-- <span v-if="$v.step2.contact_number.minlength">Please enter minimum 8 Characters</span> -->
                        </div>
                       
                      </div>
                    </div>
                   
                      <div class="form-row">
                      <div class="form-holder">
                        <label for="">Receiver Full Address</label>
                              <input
                                class="form-control mb-0 h50"
                                type="text"
                                id="delivery_full_address"
                                name="delivery_full_address"
                                v-model="$v.step2.delivery_full_address.$model"
                                placeholder="Full address inclusive of unit number eg. #02-05"
                                aria-label="Merchant Address"
                              
                              />
                              <div v-if="$v.step2.delivery_full_address.$error" class="invalid-feedback">
                                  <span v-if="$v.step2.delivery_full_address.$error">Please Enter Full Address</span>
                                </div>
                      </div>
                    </div>
                        <div class="form-row ">
                          <div class="form-holder">
                            <label for="">Receiver Address (For GPS) * </label>
                           
                            <div class="search-box d-flex">
                           <div class="position-relative w-100">
                            <!-- <vue-google-autocomplete
                            class="form-control"
                            id="delivery_address"
                            name="delivery_address"
                            type="text"
                            v-model="delivery_address"
                            v-on:placechanged="getDeliveryAddressData"
                            country="sg"
                            placeholder="Search for an area or Location name"
                          ></vue-google-autocomplete> -->
                            <vue-google-autocomplete
                              type="text"
                               id="delivery_address"
                              name="delivery_address"
                              class="form-control"
                              placeholder="Search for an area or location name"
                              v-model="$v.step2.delivery_address.$model"
                              v-on:placechanged="getDeliveryAddressData"
                              v-on:change="getChangeDeliveryAddressData"
                              :class="{'is-invalid':$v.step2.delivery_address.$error}"
                             
                              >
                            </vue-google-autocomplete>
                               <div v-if="$v.step2.delivery_address.$error" class="invalid-feedback">
                                  <span v-if="$v.step2.delivery_address.$error">Please Enter Delivery Address</span>
                                </div>
                           </div>
                        </div>
                         <div class="review-boxs mt-4 delivery-time">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0">
                   Delivery Schedule
                  </h6>
                    <p class="mb-0 mt-2">
                  {{
                    (scheduleDateTime.time && scheduleDateTime.time != '' && scheduleDateTime.time != 'asap') ? 
                    scheduleDateTime.displayText : 
                    (
                      ''
                    )
                  }}
                </p>
                  <delivery-time ref="DeliveryTime" v-on:setOrderTime="onSetOrderTime"></delivery-time>
                </div>

                          </div>
                        </div>
                        </div>
            </tab-content>
            <tab-content title="Product Details" 
            :before-change="validateThirdAsync"
            icon="ti-check">
              <div class="product_details" v-for="(product_detail,k) in $v.product_details.$each.$iter" :key="k">
                
                      <div class="form-row"> 
                        <div class="form-holder" >
                              <label for="">Item Name And Quantity</label>
                              
                            <textarea
                              id="product_descriptions"
                              name="product_descriptions[]"
                              class="form-control mb-0 h50"
                              row="10"
                              v-model="product_detail.product_descriptions.$model"
                              placeholder="Item Name And Quantity eg. Shampoo x 2 Food x 2 packets"
                              :class="{ 'is-invalid': product_detail.product_descriptions.$error }"
                            >
                            </textarea>
                              <div v-if="product_detail.product_descriptions.$error" class="invalid-feedback">
                                  <span v-if="product_detail.product_descriptions.$error">Please enter Item Name And Quantity</span>
                                </div>
                          </div>
                      </div>
                        <div class="form-row"> 
                            <div class="form-holder" >
                              <label for="">Total Item's Weight or Dimension</label>
                                <!-- <textarea
                                class="form-control mb-0 h50"
                                type="text"
                                 row="10"
                                name="product_weight[]"
                                v-model="product_detail.product_weight.$model"
                                placeholder="Weight or Dimension eg. 40 x 25 x 25 cm/ 10KG
    Max 70 x 50 x 50cm / 25kg
    Max 110 x 80 x 50cm / 60kg"
                                aria-label="Weight or Dimension eg. 40 * 25 * 25 cm/ 8KG"
                                 :class="{ 'is-invalid': product_detail.product_weight.$error }"
                                >
                                </textarea> -->
                                 <select class="form-control" 
                                    name = "product_weight[]"  
                                    id = "product_weight"
                                    v-model="product_detail.product_weight.$model"
                                    :class="{ 'is-invalid': product_detail.product_weight.$error }"
                                     v-on:change="changeItemWeight"
                                      >
                                        <option value>Select Item Weight or Dimension</option>
                                        <option value="1">40 x 25 x 25 cm/ 10KG</option>
                                        <option value="2">Max 70 x 50 x 50cm / 25kg</option>
                                        <option value="3">Max 110 x 80 x 50cm / 60kg</option>
                                    </select>
                                <div v-if="product_detail.product_weight.$error" class="invalid-feedback">
                                  <span v-if="product_detail.product_weight.$error">Please enter Total Item's Weight or Dimension</span>
                                </div>
                            </div>
                          </div>
                          <div class="form-row"> 
                            <div class="form-holder" >
                              <label for="">Item Halal  * </label>
                                 <input type="radio" 
                                 class="radio-button" 
                                 value="0" 
                                 v-model="product_details[k].product_halal"
                                 
                                 /> Halal Item    <br>
                                  <!-- <input type="radio" 
                                 class="radio-button" 
                                 value="1"
                                 v-model="product_details[k].product_halal"
                                 /> Non Halal  <br> -->
                                  <!-- <input type="radio" 
                                 class="radio-button" 
                                 value="2" 
                                 v-model="product_details[k].product_halal"
                                 
                                 /> Halal Item & Non Halal -->
                            </div>
                          </div>
                         

                          <div class="form-row form-group"> 
                            <!-- <div class="form-holder" >
                                  <label for="">Item Quantity * </label>
                                  <input
                                  class="form-control mb-0 h50"
                                  type="text"
                                  id="product_qty"
                                  maxlength="2"
                                  name="product_qty[]"
                                  v-model="product_detail.product_qty.$model"
                                  placeholder="Item Quantity"
                                  aria-label="Item Quantity"
                                  v-on:keypress="isNumber($event)"  
                                :class="{ 'is-invalid': product_detail.product_qty.$error }"
                                  
                                />
                                <div v-if="product_detail.product_qty.$error" class="invalid-feedback">
                                  <span v-if="product_detail.product_qty.$error">Please enter Product Quantity</span>
                                </div>
                            </div> -->
                            <div class="form-holder" >
                              <!-- <i class="fa fa-plus-circle" v-show="k == product_details.length-1" @click="add(k)" style="top:38px;right:90px;"></i>
                              <i class="fa fa-minus-circle" @click="remove(k)" v-show="product_details.length >= 2 " style="top:38px;right:auto;"></i> -->
                            </div>
                          </div>
               
              </div>
             
            </tab-content>
            <tab-content title="Payment Details"
                        :before-change="validateFourAsync"
                        icon="ti-credit-card">
                  <div class="form-row"> 
                    <div class="form-holder" >
                      <label for="">Select Vehicle * </label>
                         <select class="form-control" 
                         name = "vehicle_name"  
                         id = "vehicle_name"
                         v-model="$v.step4.vehicle_name.$model"
                          v-on:change="changeVehicle"
                          :class="{ 'is-invalid': $v.step4.vehicle_name.$error }"
                           disabled="true"
                          >
                            <option value>Choose Delivery Vehicle</option>
                            <option value="Bike">Bike</option>
                            <option value="Car">Car</option>
                            <option value="MPV">MPV</option>
                        </select>
                      <div v-if="$v.step4.vehicle_name.$error" class="invalid-feedback">
                        <span v-if="!$v.step4.vehicle_name.$required">Please Select Any Vehicle Type</span>
                      </div>
                    </div>
                  </div>
                    <div class="form-row form-group"> 
                      <div class="form-holder" >
                            <label for="">Computed Delivery Charges </label>
                            <input
                            class="form-control mb-0 h50"
                            type="text"
                            id="delivery_fees"
                            maxlength="2"
                            name="delivery_fees"
                            v-model="this.step4.delivery_fees"
                            placeholder="Item Quantity"
                            aria-label="Item Quantity"
                            readonly="true"
                            
                          />
                      </div>
                    </div>
                    <div class="form-row form-group"> 
                      <div class="form-holder" >
                            <label for="">PayNow </label>
                            <input
                            class="form-control mb-0 h50"
                            type="text"
                            name="payment_details"
                            value = "UEN 202030304K"
                            placeholder="Account Details UEN 202030304KHGR"
                            aria-label="Account Details UEN 202030304KHGR"
                            readonly="true"
                            
                          />
                      </div>
                    </div>
                    <div class="form-row form-group">
                      <div class="form-holder">
                        <label for="">Payment Photo </label>
                         <input type="file" 
                         accept="image/*"
                        @change="previewImage" 
                        :class="{ 'is-invalid': $v.step4.payment_photo.$error }"
                        class="form-control-file  mb-0 h50" id="my-file">
                      <div v-if="$v.step4.payment_photo.$error" class="invalid-feedback">
                        <span v-if="!$v.step4.payment_photo.$required">Please Upload Payment Photo</span>
                      </div>
                           <div class="border p-2 mt-3" v-if="this.step4.payment_photo">
                            <p>Preview Here:</p>
                            <template >
                              <img :src="this.step4.payment_photo" class="img-fluid" />
                              <p class="mb-0">file name: {{ image.name }}</p>
                              <p class="mb-0">size: {{ image.size/1024 }}KB</p>
                            </template>
                          </div>
                      </div>
                    </div>
            </tab-content>
            
            <div class="loader" v-if="loadingWizard"></div>
          </form-wizard>
      
    </div>
    <placed-order v-if="this.placedOrder == true && this.isOrderDetails == false && this.orderListing == false" ref="PlacedOrder" :orderid = this.orderid :company_emails = this.step1.company_email></placed-order>
    <view-order v-if="this.orderListing == true" ref="PlacedOrder" :searchOrder = this.searchOrder ></view-order>    
    <view-order-details v-if="this.isOrderDetails == true && this.orderListing == false" ref="PlacedOrder" :ordersDetail = this.orderDetails></view-order-details> 
  </div>
     
  </div>
 
</template>
<script>
import { required, numeric,email } from "vuelidate/lib/validators";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import firebase from "firebase";
import Setting from "../apis/setting";
import DeliveryTime from "../components/DeliveryTime.vue";
import PlacedOrder from "../components/PlacedOrder.vue";
import ViewOrder from "../components/ViewOrder.vue";
import ViewOrderDetails from "../components/ViewOrderDetails.vue";


export default {
  components: {
    VueGoogleAutocomplete,
    DeliveryTime,
    PlacedOrder,
    ViewOrder,
    ViewOrderDetails
  },
  data() {
    return {
      loadingOtp : false,
      loadingWizard: false,
      verifiedOTP: false,
      showOtp: false,
      placedOrder:false,
      isOrderDetails: false,
      orderListing : false,
      orderDetails:[],
      orderListingData : [],
      orderid : 0,
      setting:{},
      searchOrder:"",
      image:"",
      scheduleDateTime:{},
      selectedSecondaryTime: "",
      delivery_kms : 0,
      step1: {
        company_name: "",
        company_contact_number: "",
        company_email:"",
        company_address:"",
        pickup_address: "",
        pickup_latitude: "",
        pickup_longitude: "",
      },
      step2: {
        delivery_first_name: "",
        delivery_last_name: "",
        delivery_address: "",
        delivery_latitude: "",
        delivery_longitude: "",
        contact_number: "",
        delivery_full_address: "",
        scheduleDateTime:{},
      },
      product_details: [
        {
          product_halal: 1,
          product_weight:"",
          product_descriptions: "",
        },
      ],
      step4:{
        vehicle_name: "",
        delivery_fees:0,
        payment_photo:"",
      },
    };
  },
  validations: {
    step1: {
      company_name: {
        required,
      },
      company_email:{
        required,
        email
      },
       company_address:{
        required,
      
      },
      company_contact_number:{
        required,
        numeric,
      },
      pickup_address: {
        required,
      },
    },
    step2: {
      delivery_first_name: {
        required,
      },
      delivery_last_name: {
        required,
      },
      delivery_full_address:{
        required
      },
      contact_number: {
        required,
        numeric,
      },
      delivery_address: {
        required,
      },
      // scheduleDateTime:{
      //   required,
      // },
    },
    product_details: {
      required,
      $each: {
        product_descriptions: {
          required,
        },
        product_weight:{
          required,
        }
      },
    },
    step4: {
     vehicle_name:{
       required,
     },
    payment_photo:{
      required,
    }
    },
  },
  mounted() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function () {},
      }
    );
     Setting.settings(
        40818
        ).then((response) => {
          this.setting = response.data.settings;
          //console.log(this.setting);
        });
   localStorage.setItem('delivery_kms',0);
  },
  
  methods: {
    onComplete: function () {
      // console.log("Last Tabs");
        this.$v.step4.$touch();
      // if (this.$v.step4.$invalid) {
        if(!this.step4.vehicle_name){
           return false;
          }else {
            const fd = new FormData();
           
            var data = {
                vendor_id: 40818,
                restaurant_name: this.step1.company_name,
                restaurant_contact_number: this.step1.company_contact_number,
                restaurant_email:this.step1.company_email,
                restaurant_full_address: this.step1.company_address,
                restaurant_gps_address: this.step1.pickup_address,
                restaurant_latitude: this.step1.pickup_latitude,
                restaurnat_longitude: this.step1.pickup_longitude,
                customer_user_name: this.step2.delivery_first_name,
                customer_last_name: this.step2.delivery_last_name,
                delivery_address: this.step2.delivery_address,
                delivery_latitude: this.step2.delivery_latitude,
                delivery_longitude: this.step2.delivery_longitude,
                customer_mobile_number: this.step2.contact_number,
                delivery_full_address: this.step2.delivery_full_address,
                future_delivery_date: this.scheduleDateTime.date + ' ' + this.scheduleDateTime.time,
                vehicle_name: this.step4.vehicle_name,
                delivery_fee: this.step4.delivery_fees,
                delivery_kms: localStorage.getItem('delivery_kms')
                
            };
            for(const item in data ){
              fd.append(`${item}`,data[item]);
            }
            fd.append('product_details',JSON.stringify(this.product_details));
            fd.append('payment_photo', this.image);
           
          Setting.PlaceOrder(
            fd
            ).then((response) => {
            //  console.log(response.data);
            if(response.data.code == 200){
              this.loadingWizard = true;
              this.orderid = response.data.Result;
              this.$toasted.success(response.data.msg, { duration: 5000 });
              this.placedOrder = true;
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(true);
                  }, 1000);
                });
             // this.$router.reload()

            }
          });  
        
      }

    },
    setLoading: function (value) {
      this.loadingWizard = value;
    },
    handleValidation: function (isValid, tabIndex) {
      console.log("Tab:" + isValid +"valid:" + tabIndex);
    },
    validateAsync: function () {
      if (!this.$v.step1.company_contact_number.$invalid  && this.verifiedOTP == false) {
      this.verifyOTP();
      }
      if (this.verifiedOTP == true) {
         console.log("Address"+ this.step1.company_address);
            this.$v.step1.$touch();
            if (this.$v.step1.$invalid) {
              // this.$emit('on-error',this.$v.step1.$invalid);
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(false);
                }, 1000);
              });
            } else {
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(true);
                }, 1000);
              });
            }
      }else{
        this.$v.step1.$touch();
        if (this.$v.step1.company_contact_number.$invalid) {
          return;
        } else {
          this.$toasted.error("Verification OTP Required", { duration: 5000 });
          return new Promise((reject) => {
            setTimeout(() => {
              reject(false);
            }, 1000);
          });
        }
      }
    },

    validateSecondAsync: async function () {
       if (
          !this.scheduleDateTime.time || 
          this.scheduleDateTime.time == '' || 
          this.scheduleDateTime.time == 'asap'
        ) {
          this.$toasted.error("Please Select the Delivery Time slot", { duration: 5000 });
          this.$refs.DeliveryTime.openTime();
          return false;
        }
        this.$v.step2.$touch();
        if (this.$v.step2.$invalid) {
          // this.$emit('on-error',this.$v.step1.$invalid);
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(false);
            }, 1000);
          });
        } else {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(true);
            }, 1000);
          });
        }
    },
    validateThirdAsync: function () {
      this.$v.product_details.$touch();
      if (this.$v.product_details.$invalid) {
        // this.$emit('on-error',this.$v.step1.$invalid);
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(false);
          }, 1000);
        });
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 1000);
        });
      }
    },
    validateFourAsync:function (){
         this.$v.step4.$touch();
      if (this.$v.step4.$invalid) {
          this.step4.delivery_fees = 0;
        // this.$emit('on-error',this.$v.step1.$invalid);
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(false);
          }, 1000);
        });
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 1000);
        });
      }
    },

    getAddressData: function (addressData, placeResultData) {
      var latlongs = {
        city: this.city,
        state: this.state,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.$emit("getLatLong", latlongs);
      if(this.step1.pickup_latitude != "" && this.step1.pickup_longitude != ""){
        localStorage.setItem('delivery_kms',0);
      }
      this.step1.pickup_address = placeResultData.formatted_address;
      this.step1.pickup_latitude = addressData.latitude;
      this.step1.pickup_longitude = addressData.longitude;
    },
    getChangAddressData: function (addressData) {
      if (addressData == "") {
        this.step1.pickup_address = "";
        this.step1.pickup_latitude = "";
        this.step1.pickup_longitude = "";
      }
    },
    getDeliveryAddressData: function (addressData, placeResultData) {
      var latlongs = {
        city: this.city,
        state: this.state,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.$emit("getLatLong", latlongs);
       if(this.step2.delivery_latitude != "" && this.step2.delivery_longitude != ""){
        localStorage.setItem('delivery_kms',0);
      }
      this.step2.delivery_address = placeResultData.formatted_address;
      this.step2.delivery_latitude = addressData.latitude;
      this.step2.delivery_longitude = addressData.longitude;
    },
    getChangeDeliveryAddressData: function (addressData) {
      if (addressData == "") {
        this.step2.delivery_address = "";
        this.step2.delivery_latitude = "";
        this.step2.delivery_longitude = "";
      }
    },
    add() {
      if (this.$v.product_details.$invalid) {
        return;
      } else {
        this.product_details.push({
          product_name: "",
          product_descriptions: "",
          product_qty: "",
        });
      }
    },
    remove(index) {
      this.product_details.splice(index, 1);
    },
    checkNumber() {
      this.$v.step1.company_contact_number.$touch();

      if (this.$v.step1.company_contact_number.$invalid) {
        return;
      } else {
        if (this.step1.company_contact_number.length != 8) {
          this.$toasted.error("Mobile number invalid", { duration: 5000 });
        } else {
            this.loadingOtp =true;
          var appVerifier = window.recaptchaVerifier;
          firebase
            .auth()
            .signInWithPhoneNumber(
              "+65" + this.step1.company_contact_number,
              appVerifier
            )
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
              this.showOtp = true;
            
              setTimeout(() => this.otpJs(), 1000);
              this.checkMobile = false;
              this.showPassword = false;
            })
            .catch((error) => {
              this.$toasted.error(error, { duration: 5000 });
            });
        }
      }
    },
    getCode() {
      var code1 = document.getElementById("digit-1").value;
      var code2 = document.getElementById("digit-2").value;
      var code3 = document.getElementById("digit-3").value;
      var code4 = document.getElementById("digit-4").value;
      var code5 = document.getElementById("digit-5").value;
      var code6 = document.getElementById("digit-6").value;
      return (
        code1 + "" + code2 + "" + code3 + "" + code4 + "" + code5 + "" + code6
      );
    },
    otpJs() {
      this.loadingOtp = false;
      window
        .jQuery(".digit-group")
        .find("input")
        .each(function () {
          window.jQuery(this).attr("maxlength", 1);
          window.jQuery(this).on("keyup", function (e) {
            var parent = window.jQuery(window.jQuery(this).parent());
            console.log("parent"+parent);
            console.log("key code"+e.keyCode);
            if (e.keyCode === 8 || e.keyCode === 37) {
              var prev = parent.find(
                "input#" + window.jQuery(this).data("previous")
              );
              if (prev.length) {
                window.jQuery(prev).select();
              }
            } else if (
              (e.keyCode >= 48 && e.keyCode <= 57) ||
              e.keyCode === 39 || e.keyCode == 229
            ) {
              
              var next = parent.find(
                "input#" + window.jQuery(this).data("next")
              );
             if (next.length) {
                window.jQuery(next).select();
              } else {
                if (parent.data("autosubmit")) {
                  parent.submit();
                }
              }
            }
          });
        });
    },
    verifyOTP() {
      
      // e.preventDefault();
      let vm = this;
      var code1 = document.getElementById("digit-1").value;
      if (code1 != "") {
        window.confirmationResult
          .confirm(this.getCode())
          .then(function () {
            vm.verifiedOTP = true;
            vm.$toasted.success("OTP Verified", { duration: 5000 });
          })
          .catch(function (err) {
            if (err.code == "auth/invalid-verification-code") {
              vm.$toasted.error("Invalid OTP", { duration: 4000 });
            }
            if (err.code == "400") {
              vm.$toasted.error("Session Expired", { duration: 4000 });
            }
          });
      } else {
        this.$toasted.error("Verification OTP Required", { duration: 5000 });
      }
    },
    isNumber: function (evt) {
      let keyCode = evt.keyCode ? evt.keyCode : evt.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        evt.preventDefault();
      }
    },
    isCharacter: function (evt) {
     
        let char = String.fromCharCode(evt.keyCode); // Get the character
        if(/^[A-Za-z\s]+$/.test(char)) return true; // Match with regex 
        else evt.preventDefault(); // If not match, don't add to input text

    },
    changeVehicle (){
      console.log("Change Event   --- "+!this.step4.vehicle_name);
      
    },
    changeItemWeight (event){
      if(event.target.value == 1){
        this.step4.vehicle_name = "Bike";
      }
      else if(event.target.value == 2){
        this.step4.vehicle_name = "Car";
      }
      else if(event.target.value == 3){
        this.step4.vehicle_name = "MPV";
      }

       Setting.DeliveryCharge({
          vendor_id: 40818,
          pickup_latitude: this.step1.pickup_latitude,
          pickup_longitude: this.step1.pickup_longitude,
          delivery_latitude: this.step2.delivery_latitude,
          delivery_longitude: this.step2.delivery_longitude,
          vehicle_name: this.step4.vehicle_name,
          delivery_kms: localStorage.getItem('delivery_kms')
        }).then((response) => {
          localStorage.setItem('delivery_kms',response.data.Result.km);
          this.step4.delivery_fees = response.data.Result.delivery_fees;
        });
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.step4.payment_photo = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    onSetOrderTime(value) {
      this.scheduleDateTime = value;
    },
    viewOrderDetails() {
      if(this.searchOrder != ''){
        if(this.searchOrder.length == 10){
             this.orderListing = true;
          // Setting.onGoingOrder({
          //   vendor_id: 40818,
          //   search_number: this.searchOrder,
          //   status:"OnGoing",
          // }).then((response) => {
          //   if(response.data.code == 200){
          //     this.orderListing = true;
          //     this.orderListingData = response.data.Result;
          //   }else{
          //     this.$toasted.error(response.data.msg, { duration: 4000 });
          //   }
            
          //   });
        }else{
          this.$router.push({ name: 'order-details',params: { order_id:this.searchOrder } });

        }
        }
      

    }
  },
};
</script>