<template>
  <div>
    <a href="javascript:void(0)" class="lnk" @click="openTime">Set Schedule</a>
    <div
      class="modal fade deliverytime-main"
      id="changetime"
      tabindex="-1"
      aria-labelledby="changetimeLabel"
      aria-hidden="true"
      ref="TimeModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="changetimeLabel">When You Want It Delivered?</h4>
          </div>
          <div class="modal-body p-0">
            <div class="d-flex align-content-start">
              <ul  class="nav nav-tabs flex-column border-0">
                <li class="nav-item" v-for="(day,index) in allDays" :key="index">
                  
                  <a href="javascript:void(0);" @click="selectDate(day.date, day.fulldayname, day.isToday)" class="nav-link navbar-default" data-toggle="tab" :class="{'active':(selectedDate == day.date)}">
                    {{(CurrentDate == isTodayDate && index == 0 ) ? 'Today' : ((day.date == isTodayDate) ? 'Tomorrow' : day.dayname)}}
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div id="tab1" class="tab-pane active tab-box">
                  <form class="boxed d-flex flex-column">
                    <!-- <div class="asap" v-if="isToday && isAsapEnable">
                      <input type="radio" id="asap" name="time" value="asap" v-model="selectedTime"/>
                      <label for="asap">As Soon As Possible</label>
                    </div> -->
                    <!-- <div v-else> -->
                      <div v-if="times.reduce((a,t)=>t.length > 0?1:a,0) > 0">
                        <template v-for="(t,i) in times">
                        <template v-for="(time,index) in t">
                          <div class="timeslots" v-if="index+1 !== t.length" :key="i+''+index">
                            <input type="radio" :id="'time_'+i+'_'+index" name="time" :value="t[index]" v-model="selectedTime" @click="setSecondaryTime(t[index+1])"/>
                            <label :for="'time_'+i+'_'+index">
                              {{moment(t[index],timeFormat).format('hh:mm A')}} - 
                              {{moment(t[index+1],timeFormat).format('hh:mm A')}}
                            </label>
                          </div>
                        </template>
                      </template>
                      </div>
                      <div v-else class="notimeslot">
                        <!-- <img src="@/assets/images/no-time-slot.svg" /> -->
                        <p>No time slot available</p>
                      </div>
                    <!-- </div> -->
                    
                  </form>
                </div>
              </div>
              <div class="loaderdiv h-100 d-flex justify-content-center align-items-center" v-if="isLoading">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </div>
          </div>
          <div class="modal-footer full-btn d-block">
            <a href="javascript:void(0);" @click="setTime()">Set Delivery time</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Setting from "../apis/setting";
// import $ from 'jquery';

export default {
  data() {
    return {
      moment:moment,
      isLoading:false,
      selectedDate:'',
      times:[],
      setting:{},
      selectedTime:'',
      selectedSecondaryTime:'',
      isDelivery:1,
      isToday: true,
      isAsapEnable: true,
      timeFormat: 'HH:mm:ss',
      dateFormat: 'YYYY-MM-DD',
      defaultDayname: '',
      time_slots:30,
      store_delivery_time: [],
      store_takeaway_time: [],
      system_start_time: '',
      system_end_time: '',
      is_same_day_delivery:1,
      is_enable_schedule_order_after_business_hours:0,
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      currentDate: new Date(),
      currentTime: new Date().toLocaleTimeString(),
      currentMinute: new Date().getMinutes(),
      currentDay: '',
      allDays: [],
      opencloseTimes: [],
      statTimeSlot: [],
      restaurantTimingList: []
    }
  },
  computed: {
    //...mapGetters("setting",["settings"]), //To get admin settings
  },
  mounted() {
    this.currentDay = this.days[this.currentDate.getDay()];
     Setting.settings(
        40818
        ).then((response) => {
          this.setting = response.data.settings;
          console.log(this.setting);
        });
    //this.getsetting(this.$auth.getVendorId()); //Get admin settings
  },
  methods: {
    // //...mapActions("setting",["getsetting"]), //To get admin settings
    // ...mapActions("restaurant", [ "getRestaurantTimingData"]), //To get restaurant timings
    // // To re-init all the data
    openTime() {
        this.system_start_time = (this.setting && this.setting.system_start_time != '') ? this.setting.system_start_time.split(' ').pop() : '00:00:00';
        this.system_end_time = (this.setting.system_end_time != '') ? this.setting.system_end_time.split(' ').pop() : '23:59:00';
        //this.is_enable_schedule_order_after_business_hours = this.$auth.setting.is_enable_schedule_order_after_business_hours;
      this.store_delivery_time = [];
      this.store_takeaway_time = [];
      this.selectedDate = '';
      this.selectedTime = '';
      this.selectedSecondaryTime = '';
      this.defaultDayname = '';
      //this.currentTime = new Date().toLocaleTimeString();
      this.currentTime = moment().add(1.5, 'hours').format(this.timeFormat);
      this.currentMinute = new Date().getMinutes();
      this.isTodayDate = moment().add(1.5, 'hours').format(this.dateFormat);
      this.CurrentDate = moment().format(this.dateFormat);
      this.opencloseTimes = [];
      this.times = [];
      this.isToday = true;
      this.isAsapEnable = false;
      // this.isLoading = true;
      // $('#changetime').modal('show');
      window.$(this.$refs.TimeModal).modal('show');
      let beforeTime = moment(this.system_start_time, this.timeFormat);
      let afterTime = moment(this.system_end_time, this.timeFormat);
      if (moment(this.currentTime, this.timeFormat).isBetween(beforeTime, afterTime)) {
        this.isAsapEnable = true;
      } else {
        this.isAsapEnable = false;
      }
      //Get restaurant timings
       this.createDays();
    },
    // // To create left side days list
    createDays() {
     
      this.allDays = [];
      let daysToCalculate = 10;
      let dayId = 1;
      if (this.is_same_day_delivery) {
         if(this.isTodayDate == this.CurrentDate){
        let dayname = this.shortDays[this.currentDate.getDay()];
        let fulldayname = this.days[this.currentDate.getDay()];
        this.defaultDayname = this.days[this.currentDate.getDay()];
        let monthname = this.currentDate.toLocaleString('default', { month: 'short' });
        let monthdaynumber = this.currentDate.getDate();
        let calDayName = dayname + ', ' + monthname + ' ' + monthdaynumber;
        let date = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth()+1) + '-' + this.currentDate.getDate();
        let dayData = {id: dayId, dayname: calDayName, date: moment(date).format(this.dateFormat), fulldayname: fulldayname, isToday: true, isTomorrow: false};
        this.allDays.push(dayData);
        this.selectedDate = moment(date).format(this.dateFormat);
        dayId++;
        daysToCalculate = (daysToCalculate - 1);
         }
      }
      for (let day = 1; day <= daysToCalculate; day++) {
        let currentCalDate = new Date();
        let fulldate = new Date(currentCalDate.setDate(currentCalDate.getDate() + day));
        let dayname = this.shortDays[fulldate.getDay()];
        let fulldayname = this.days[currentCalDate.getDay()];
        let monthname = fulldate.toLocaleString('default', { month: 'short' });
        let monthdaynumber = fulldate.getDate();
        let calDayName = dayname + ', ' + monthname + ' ' + monthdaynumber;
        let date = currentCalDate.getFullYear() + '-' + (currentCalDate.getMonth()+1) + '-' + currentCalDate.getDate();
        if(date === this.isTodayDate){
        let dayData = {id: dayId, dayname: calDayName, date: moment(date).format(this.dateFormat), fulldayname: fulldayname, isToday: true, isTomorrow: false};
        this.allDays.push(dayData);
        }else{
          let dayData = {id: dayId, dayname: calDayName, date: moment(date).format(this.dateFormat), fulldayname: fulldayname, isToday: false, isTomorrow: (day == 1) ?true : false};
          this.allDays.push(dayData);
        }
        
        if (!this.is_same_day_delivery && day == 1) {
          this.selectedDate = moment(date).format(this.dateFormat);
          this.defaultDayname = fulldayname;
        }

        dayId++;
     //   }
      }
      console.log("All Days "+ JSON.stringify(this.allDays));
     this.createTimes();
    },
    // To create static time slot from system start and end date
    createTimes() {
      let openTime = '';
      let closeTime = '';
      openTime = this.system_start_time;
      closeTime = this.system_end_time;
      // this.opencloseTimes.push({openTime: openTime, closeTime: closeTime, day: this.currentDay});

      let deltime = [
        {
          open_time: openTime,
          close_time: closeTime,
          day: 'Monday'
        },
        {
          open_time: openTime,
          close_time: closeTime,
          day: 'Tuesday'
        },
        {
          open_time: openTime,
          close_time: closeTime,
          day: 'Wednesday'
        },
        {
          open_time: openTime,
          close_time: closeTime,
          day: 'Thursday'
        },
        {
          open_time: openTime,
          close_time: closeTime,
          day: 'Friday'
        },
        {
          open_time: openTime,
          close_time: closeTime,
          day: 'Saturday'
        },
        {
          open_time: openTime,
          close_time: closeTime,
          day: 'Sunday'
        },
      ];
      deltime.forEach((elm) => {
        if (this.system_start_time > elm.open_time) {
          openTime = this.system_start_time;
        } else {
          openTime = elm.open_time;
        }
        if (this.system_end_time > elm.close_time) {
          closeTime = elm.close_time;
        } else {
          closeTime = this.system_end_time;
        }
        this.opencloseTimes.push({openTime: openTime, closeTime: closeTime, day: elm.day});
      });

      let startTime = this.system_start_time;
      let endTime = (this.system_end_time == '00:00:00') ? '23:59:00' : this.system_end_time;
      let convOpenTime = moment(startTime,this.timeFormat);
     
      do {
        this.statTimeSlot.push(convOpenTime.format(this.timeFormat));
        convOpenTime.add(this.time_slots,'minutes');
      } while(moment(endTime,this.timeFormat).isAfter(convOpenTime));
      
      if (this.statTimeSlot.length > 1) {
        let sTime = moment(this.statTimeSlot[this.statTimeSlot.length-1], this.timeFormat);
        let eTime = moment(endTime, this.timeFormat);
        let dur = moment.duration(eTime.diff(sTime));
        // let hrs = parseInt(dur.asHours());
     
        let min = parseInt(dur.asMinutes());//-hrs*60;
        if (min > 0) {
          this.statTimeSlot.push(sTime.add(min, 'minutes').format(this.timeFormat));
        }
      }
   
      if (this.is_same_day_delivery) {
      this.selectDate(this.allDays[0].date, this.allDays[0].fulldayname,true);
      } else {
        this.selectDate(this.selectedDate, this.defaultDayname, false);
     }
    },
    // // To get restaurnat delivery and pickup timings
    // getRestaurantTiming() {
    //   this.getRestaurantTimingData({
    //     vendor_id: this.$auth.getVendorId(),
    //     restaurant_id:this.restaurantId,
    //     is_langauge: "en",
    //   }).then((data) => {
    //     if (data.code == 200) {
    //       this.restaurantTimingList = data.Result;
    //       if (this.restaurantTimingList) {
    //         if (this.restaurantTimingList.delivery_timing) { //For Delivery
    //           this.restaurantTimingList.delivery_timing.forEach((elm) => {
    //             if (elm.status == 1) {
    //               this.store_delivery_time.push(elm);
    //             }
    //           });
    //         }
    //         if (this.restaurantTimingList.pickup_timing) { //For Takeaway
    //           this.restaurantTimingList.pickup_timing.forEach((elm) => {
    //             if (elm.status == 1) {
    //               this.store_takeaway_time.push(elm);
    //             }
    //           });
    //         }
    //       }
    //       this.createDays();
    //       this.isLoading = false;
    //     } else {
    //       this.isLoading = false;
    //       alert((data.msg != '') ? data.msg : 'Something went wrong, Please try again.');
    //     }
    //   }).catch(function() {
    //     this.isLoading = false;
    //     alert('Something went wrong, Please try again.');
    //   });
    // },
    // // On click on day this function will call to retrive all timings
    selectDate(date, fulldayname, isToday) {
      this.times = [];
      this.selectedDate = date;
      console.log("fulldayname  "+fulldayname);
      this.isToday = isToday;
      this.selectedTime = (isToday && this.isAsapEnable) ? 'asap' : '';
      this.selectedSecondaryTime = '';
      let time = this.opencloseTimes.filter((el) => el.day == fulldayname);
     
        if (time.length > 0) {
          time.forEach((elm,ind) => {
            this.times[ind] = [];
            if (!isToday) {
              let result = [];
              let openTime = elm.openTime;
              let closeTime = (elm.closeTime == '00:00:00') ? '23:59:00' : elm.closeTime;
              let convOpenTime = moment(openTime,this.timeFormat);
              do {
                result.push(convOpenTime.format(this.timeFormat));
                convOpenTime.add(this.time_slots,'minutes');
              } while(moment(closeTime,this.timeFormat).isAfter(convOpenTime));
              if (result.length > 1) {
                let startTime = moment(result[result.length-1], this.timeFormat);
                let endTime = moment(closeTime, this.timeFormat);
                let duration = moment.duration(endTime.diff(startTime));
                // let hours = parseInt(duration.asHours());
                let minutes = parseInt(duration.asMinutes());//-hours*60;
                if (minutes > 0) {
                  result.push(startTime.add(minutes, 'minutes').format(this.timeFormat));
                }
              }
              result.forEach((el) => {
                this.times[ind].push(el);
              });
            } else {
              let firstEl = this.statTimeSlot.find(el => el >= this.currentTime);

              let firstEldone = false;
              let result = [];
              let openTime = elm.openTime;
              let closeTime = (elm.closeTime == '00:00:00') ? '23:59:00' : elm.closeTime;
              let convOpenTime = moment(openTime,this.timeFormat);
             
              var elseloop = false;
              do {
                elseloop = false
                if (!firstEldone && firstEl) {
                   console.log("convOpenTime"+convOpenTime);
                  firstEldone = true;
                  result.push(moment(this.currentTime,this.timeFormat).format(this.timeFormat));
                  result.push(moment(firstEl,this.timeFormat).format(this.timeFormat));
                   console.log("if result"+result);
                }
                let beforeTime = moment(openTime, this.timeFormat);
                let afterTime = moment(closeTime, this.timeFormat);
                if (moment(this.currentTime, this.timeFormat).isBetween(beforeTime, afterTime)) {
                  let formconvOpenTime = convOpenTime.format(this.timeFormat);
                  result.push(formconvOpenTime);
                  convOpenTime.add(this.time_slots,'minutes');
                
                } else {
                  elseloop = true;
                
                }
              } while(moment(closeTime,this.timeFormat).isAfter(convOpenTime) && !elseloop);
              let minutes = 0;
              if (result.length > 1) {
                let startTime = moment(result[result.length-1], this.timeFormat);
                let endTime = moment(closeTime, this.timeFormat);
                let duration = moment.duration(endTime.diff(startTime));
                // let hours = parseInt(duration.asHours());
                minutes = parseInt(duration.asMinutes());//-hours*60;
                if (minutes > 0) {
                  result.push(startTime.add(minutes, 'minutes').format(this.timeFormat));
                }
              }
              if (firstEl && this.isAsapEnable) {
                let temp = result.filter((el) => firstEl < el);
                this.times[ind].push(moment(this.currentTime,this.timeFormat).format(this.timeFormat));
                this.times[ind].push(moment(firstEl,this.timeFormat).format(this.timeFormat));
                temp.forEach((el) => {
                  this.times[ind].push(el);
                });
                if (result.length > 1 && minutes > 0) {
                  let st = moment(result[result.length-1], this.timeFormat);
                  if (!moment(result[result.length-1], this.timeFormat).isSame(st)) {
                    this.times[ind].push(st.format(this.timeFormat));
                  }
                }
              } else {
                result.forEach((el) => {
                  this.times[ind].push(el);
                });
              }
            }
          });
        }
      
    },
    // // To get time difference from two time
    // // getTimeDiff(srtTime, edTime) {
    // //   let minutes = 0;
    // //   let startTime = moment(srtTime, this.timeFormat);
    // //   let endTime = moment(edTime, this.timeFormat);
    // //   let duration = moment.duration(endTime.diff(startTime));
    // //   minutes = parseInt(duration.asMinutes());
    // //   return minutes;
    // // },
    // // To set end time
    setSecondaryTime(time) {
      this.selectedSecondaryTime = time;
    },
    // // On click on set delivery/pickup time this will handle and pass data
    setTime() {
      if (this.selectedDate != '' && this.selectedTime != '') {
        let selMoment = moment(this.selectedDate);
        let day = selMoment.format('ddd');
        let monthname = selMoment.format('MMM');
        let daynum = selMoment.format('D');
        let stTime = moment(this.selectedDate + ' ' + this.selectedTime).format('LT');
        let edTime = moment(this.selectedDate + ' ' + this.selectedSecondaryTime).format('LT');
        let todayTomm = '';
        switch(this.selectedDate){
          case moment().format(this.dateFormat):
            todayTomm = 'Today';
            break;
          case moment().add(1,'day').format(this.dateFormat):
            todayTomm = 'Tomorrow';
            break;
          default:
            todayTomm = day + ', ' + monthname + '-' + (daynum > 9 ? daynum : '0'+daynum);
            break;
        }
        let displayText = todayTomm + ', ' + stTime + ' - ' + edTime;
        this.$emit("setOrderTime", {date: this.selectedDate, time: this.selectedTime, displayText: displayText});
        window.$(this.$refs.TimeModal).modal('hide');
      } else {
        alert("Please select date and time both");
      }      
    }
  },
};
</script>
<style scoped>
/* To set loader on center of modle */
.loaderdiv { 
  width: 100%;
  position: absolute;
}
</style>