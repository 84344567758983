<template>
  <div id="wizard">
  <div class="order_page">
  
        
            <div class="resturant-details plr15">
              <h4 class="text-left">Orders</h4>
            </div>
            <div class="orders-tab-main plr15 mt-4">
                <b-tabs content-class="mt-3" lazy>
                  <b-tab title="Ongoing" active>
                    <on-going v-bind:searchOrder = "searchOrder"/>
                  </b-tab>
                  <b-tab title="Past" >
                    <past v-bind:searchOrder = "searchOrder"/>
                  </b-tab>
                </b-tabs>
            </div>
         
    </div>
  </div>
</template>
<script>
import OnGoing from '../components/OnGoing.vue';
import Past from '../components/Past.vue';
export default {
  components: {
    OnGoing,
    Past,
  },
  props:['searchOrder'],
  mounted() {
  }
};
</script>
