<template>
  <div id="ongoing" class="tab-pane tab-box">
    <div class="orders-lst" v-if="pastorders.length > 0">
      <router-link
        v-for="(past,index) in pastorders"
        :key="index"
      :to="{ name: 'order-details',params: { order_id: past.id }}"
      >
        <div class="order-item d-flex align-items-start">
          <div class="img-cls">
            <img :src="past.icon_image" width="40px" height="40px" />
          </div>
          <div class="order-data ml-3 d-flex align-items-start justify-content-between w-100">
            <div class="orders-status-main delivery-link">
              <h6 class="mb-1">{{past.restaurant_name}}</h6>
              <span ></span> <span>{{ parseFloat(past.total_amount).toFixed(2)}}</span>
              <p class="mt-2">{{past.order_date}}</p>
            </div>
            <div class="order-status" v-if="past.order_status == 'Delivered'">
              <p class="delivered order-status-btn">{{past.order_status}}</p>
            </div>
            <div class="order-status" v-else>
              <p class="canceled order-status-btn">{{past.order_status}}</p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div v-else class="order-offers">
      <p>No Past Order Found</p>
    </div>
    </div>
</template>
<script>
import moment from "moment";
import Setting from "../apis/setting";
export default {
  props:['searchOrder'],
  data() {
    return {
      pastorders: [],
      perPage: 10,
      moment: moment,
      totalrecord:20,
      isStopCallingApi:false,
      skip:0,
      is_skel_loader:true
    };
  },
  mounted() {
    console.log("Past ORders searchOrder"+ this.searchOrder);
    if(this.skip == 0){
      this.getPastOrders();
    }
     window.addEventListener("scroll", () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        if (!this.isStopCallingApi) {
          this.getPastOrders();
          this.is_skel_loader = false;
        }
      }
    });
  },
  methods: {
    getPastOrders() {
      this.is_skel_loader = true;
       Setting.onGoingOrder({
          vendor_id: 40818,
          search_number: this.searchOrder,
          status:"Past",
          skip:this.skip,
          total_record:this.totalrecord,
        }).then((response) => {
          console.log("Past Order"+response.data.Result);
          if(response.data.code == 200){
           this.is_skel_loader = false;
            this.totalrecord = this.totalrecord+20;
            if (!this.pastorders.length) {
            response.data.Result.forEach((resOrder) => {
              this.pastorders.push(resOrder);
            });
          } else {
            response.data.Result.forEach((resOrder) => {
              let checkRest = this.pastorders.find((elm) => elm.id == resOrder.id);
              if (!checkRest) {
                this.pastorders.push(resOrder);
              }
            });
          }
          // this.pastorders = response.data.Result;
          }else if (response.data.Result.length == 0) {
          this.is_skel_loader = false;
          this.isStopCallingApi = true;
        }
          
          });


    }
  },
};
</script>