<template>
  <div id="wizard" >
  <div class="order_page" v-if="this.orderListing == false">
   
        <div class="searchorder d-flex mb-5">
            <div class="row">
              <div class="form-holder d-flex">
                    <input
                      class="form-control mb-0 h50 ml-md-1"
                      type="text"
                      id="searchOrder"
                      name="searchOrder"
                      v-model="searchOrder"
                      placeholder="Track Your Delivery"
                      aria-label="Track Your Delivery"
                     
                    />
                     <button @click="viewOrderDetails"  class="form-control ml-md-5 w-75" id="trackOrder">Track Order</button>
                     
              </div>
            </div>
          </div>
           <h4> Your delivery order # {{orderid}}<br> has been submitted.<br><br>
            You may contact <br><b>6484-6799</b> for any inquiry.<br><br>
            You will receive an email confirmation <br> of your delivery details at {{company_emails}}.<br>
          </h4>
    </div>
      <view-order v-if="this.orderListing == true" ref="PlacedOrder" :searchOrder = this.searchOrder :ongoingorders = this.orderListingData></view-order>    
  </div>
</template>
<script>
import ViewOrder from "../components/ViewOrder.vue";
import Setting from "../apis/setting";

export default {
  props:['company_emails','orderid'],
  components: {
    ViewOrder
  },
  data() {
    return {
      loadingOtp : false,
      loadingWizard: false,
      verifiedOTP: false,
      showOtp: false,
      placedOrder:false,
      isOrderDetails: false,
      orderListing : false,
      orderDetails:[],
      orderListingData : [],
      searchOrder:"",
      scheduleDateTime:{},
      selectedSecondaryTime: "",
    
    };
  },
  methods: {
     viewOrderDetails() {
      if(this.searchOrder != ''){
        if(this.searchOrder.length == 10){
          Setting.onGoingOrder({
            vendor_id: 40818,
            search_number: this.searchOrder,
            status:"OnGoing",
          }).then((response) => {
            if(response.data.code == 200){
              this.orderListing = true;
              this.orderListingData = response.data.Result;
            }else{
              this.$toasted.error(response.data.msg, { duration: 4000 });
            }
            
            });
        }else{
          this.$router.push({ name: 'order-details',params: { order_id:this.searchOrder } });

        }
        }
      

    }
  }
}
</script>
