<template>
   <div id="ongoing" class="tab-pane active tab-box">
   <div class="orders-lst" v-if="ongoingorders.length > 0">
        <router-link
        v-for="(order,index) in ongoingorders"
        :key="index"
        :to="{ name: 'order-details',params: { order_id: order.id }}"
        >
    
   
        <div class="order-item d-flex align-items-start">
          <div class="img-cls">
            <img :src="order.icon_image" width="40px" height="40px"/>
          </div>
          <div class="order-data ml-3 d-flex align-items-start justify-content-between w-100">
            <div class="orders-status-main delivery-link on-going-order">
              <h6 class="mb-1">{{order.restaurant_name}}</h6>
              <span ></span> <span>{{parseFloat(order.total_amount || 0).toFixed(2)}}</span>
              <p class="mt-2">{{order.order_date}}</p>
            </div>
            <div class="order-status" v-if="order.order_status == 'Placed'">
              <p class="readytoserve order-status-btn">Placed</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'InKitchen'">
              <p class="readytoserve order-status-btn">Processing</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'ReadyToServe'">
              <p class="readytoserve order-status-btn">ReadyToServe</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'OnTheWay'">
              <p class="readytoserve order-status-btn">On The Way</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'Arrived'">
              <p class="readytoserve order-status-btn">Arrived</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'Delivered'">
              <p class="delivered order-status-btn">{{order.order_status}}</p>
            </div>
            <div class="order-status" v-else-if="order.order_status == 'Cancelled'">
              <p class="canceled order-status-btn">{{order.order_status}}</p>
            </div>
          </div>
        </div>
        </router-link>
       
    
    </div>
    <div v-else class="order-offers">
      <p>No Ongoing Order Found</p>
    </div>
  </div>


  
</template>
<script>
import moment from "moment";
import Setting from "../apis/setting";

export default {
  
  props:['searchOrder'],
  data(){
      return{
          moment: moment,
          perPage: 10,
          totalrecord:20,
          isStopCallingApi:false,
          skip:0,
          is_skel_loader:true,
          ongoingorders:[],
        
      }
  },
  mounted() {
    console.log("Past ORders searchOrder"+ this.searchOrder);
    if(this.skip == 0){
      this.getPastOrders();
    }
     window.addEventListener("scroll", () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        if (!this.isStopCallingApi) {
          this.getPastOrders();
          this.is_skel_loader = false;
        }
      }
    });
  },
  methods: {
    getPastOrders() {
      this.is_skel_loader = true;
       Setting.onGoingOrder({
          vendor_id: 40818,
          search_number: this.searchOrder,
          status:"OnGoing",
          skip:this.skip,
          total_record:this.totalrecord,
        }).then((response) => {
        
          if(response.data.code == 200){
           this.is_skel_loader = false;
            this.totalrecord = this.totalrecord+20;
            if (!this.ongoingorders.length) {
            response.data.Result.forEach((resOrder) => {
              this.ongoingorders.push(resOrder);
            });
          } else {
            response.data.Result.forEach((resOrder) => {
              let checkRest = this.ongoingorders.find((elm) => elm.id == resOrder.id);
              if (!checkRest) {
                this.ongoingorders.push(resOrder);
              }
            });
          }
          // this.pastorders = response.data.Result;
          }else if (response.data.Result.length == 0) {
          this.is_skel_loader = false;
          this.isStopCallingApi = true;
        }
          
          });


    }
  },
};
</script>

